import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useReadLocalStorage } from "usehooks-ts";
import { useForm } from "react-hook-form";
import i18next from "i18next";
import axios from "axios";
import { TextField, Label, Input } from "react-aria-components";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProfileSchema, ProfileType } from "~/schemas/RegistrationSchema";
import FormModal from "./FormModal";

interface ProfileUpdateProps {
    profileData: any;
    onBack: () => void;
}

const ProfileUpdate = (props: ProfileUpdateProps) => {
    const { onBack, profileData } = props;

    i18next.loadNamespaces("fields");
    const { t } = useTranslation();
    const token = useReadLocalStorage("_t") as string;

    const fields = [
        {
            name: "firstName",
        },
        {
            name: "lastName",
        },
        {
            name: "company",
        },
        {
            name: "businessAddress",
        },
        {
            name: "businessAddress2",
            label: "businessAddress",
            optional: true,
        },
        {
            name: "city",
        },
        {
            name: "state",
        },
        {
            name: "zip",
        },
        {
            name: "email",
        },
    ];

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<ProfileType>({
        resolver: zodResolver(ProfileSchema),
        defaultValues: { ...profileData },
        mode: "all",
    });

    const [profileUpdated, setProfileUpdated] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const onSubmit = handleSubmit((data: ProfileType) => {
        setIsProcessing(true);

        axios
            .post(`${import.meta.env.VITE_API_HOST}/api/account/update`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                setProfileUpdated(true);
            })
            .catch((error) => {
                const { status, data } = error.response;

                console.log(error.response);

                if (status === 400 && !data?.status) {
                    Object.keys(data).map((fieldName: string) =>
                        setError(fieldName as keyof ProfileType, {
                            message: t(`errors:${data[fieldName]}`) || "",
                        })
                    );
                }
            })
            .finally(() => {
                setIsProcessing(false);
            });
    });

    return (
        <FormModal formTitle={`${t("changeProfile")}`} isProcessing={isProcessing} onSubmit={onSubmit} onBack={onBack}>
            <div className="w-[471px] flex flex-col mx-auto py-6 gap-4">
                {profileUpdated && <h4 className="text-center text-success">{t("profileUpdated")}</h4>}
                {fields.map(({ name, label, optional }) => (
                    <TextField
                        key={name}
                        defaultValue={profileData[name]}
                        className={`text-field ${errors[name as keyof ProfileType] ? "text-field--error" : ""}`}
                        onChange={() => setProfileUpdated(false)}
                    >
                        <Input placeholder={t(name) || ""} {...register(name as keyof ProfileType)} />
                        <Label>
                            {errors?.[name as keyof ProfileType]
                                ? errors?.[name as keyof ProfileType]?.message
                                : t(`fields:${label ? label : name}`)}{" "}
                            {optional ? <small className="text-sm">(optional)</small> : null}{" "}
                        </Label>
                    </TextField>
                ))}
            </div>
        </FormModal>
    );
};

export default ProfileUpdate;
