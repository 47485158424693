import { useState, useEffect, useContext } from "react";
import { useLocalStorage } from "usehooks-ts";
import { Tab } from "@headlessui/react";
import { IImageItem, ResultTypeIndex } from "./types";
import Bookmark from "~/components/Bookmark";
import { LanguageContext } from "~/providers/LanguageProvider";

const ImageBookmarks = () => {
    const STORAGE_NAME = import.meta.env.VITE_REGION + "_images";
    const { language } = useContext(LanguageContext);
    const [storedImages, setStoredImages] = useLocalStorage(STORAGE_NAME, "");
    const [images, setImages] = useState<IImageItem[]>([]);

    useEffect(() => {
        // Support for saved image bookmarks on previous version
        // Convert old data format to new text data format and update storage
        if (storedImages && storedImages !== "") {
            let updateStorageFlag = false;
            const imgArr = JSON.parse(storedImages).map((img: any) => {
                if (img.ImageID) {
                    updateStorageFlag = true;
                    return {
                        id: img.ImageID,
                        imageID: img.ImageID,
                        company: img.Company,
                        year: img.Year,
                        documentType: {
                            en: img.DocumentType,
                            fr: img.DocumentType,
                        },
                        imageFilePath: img.ImageFilePath,
                        fileDescription: img.FileDescription,
                        metadata: {
                            en: img.Metadata,
                            fr: img.Metadata,
                        },
                    } as IImageItem;
                }
                return img;
            });

            if (updateStorageFlag) {
                setStoredImages(JSON.stringify(imgArr));
            }
        }
    }, []);

    useEffect(() => {
        if (storedImages && storedImages !== "") {
            setImages(JSON.parse(storedImages));
        } else {
            setImages([]);
        }
    }, [storedImages]);

    function groupImages(collections: IImageItem[], groupSize: number) {
        const columnsArray: IImageItem[][] = Array.from({ length: groupSize }, () => []);

        collections.forEach((item, index) => {
            const columnIndex = index % groupSize;
            item.index = index;
            columnsArray[columnIndex].push(item);
        });

        return columnsArray;
    }

    return (
        <Tab.Panel className="w-full">
            <div className="grid grid-cols-3 gap-2 mt-5 p-2 text-left overflow-auto">
                {groupImages(images, 4)
                    .slice(0, 100)
                    .map((group, index) => (
                        <div className="grid grid-cols-1 gap-2 content-start" key={`image-result-col-${index}`}>
                            {group.map((item: IImageItem) => (
                                <Bookmark
                                    key={`bookmark-img-${item.imageID}`}
                                    imgPath={`${import.meta.env.VITE_API_HOST}${item.imageFilePath}`}
                                    alt={`${item.company} ${item.documentType[language]} ${item.year} ${item.metadata[language]}`}
                                    id={item.imageID}
                                    type={ResultTypeIndex.Image}
                                    storage={STORAGE_NAME}
                                    storageID="imageID"
                                />
                            ))}
                        </div>
                    ))}
            </div>
        </Tab.Panel>
    );
};

export default ImageBookmarks;
