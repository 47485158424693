import { useContext, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { Tab } from "@headlessui/react";
import { IFullDocumentItem, ResultTypeIndex } from "./types";
import Bookmark from "~/components/Bookmark";
import { LanguageContext } from "~/providers/LanguageProvider";

const FullDocumentBookmarks = () => {
    const STORAGE_NAME = "fullDocuments";
    const { language } = useContext(LanguageContext);
    const [storedDocs, setStoredDocs] = useLocalStorage(STORAGE_NAME, "");
    const [fullDocuments, setFullDocuments] = useState<IFullDocumentItem[]>([]);

    useEffect(() => {
        // Support for saved full document bookmarks on previous version
        // Convert old data format to new text data format and update storage
        if (storedDocs && storedDocs !== "") {
            let updateStorageFlag = false;
            const docsArr = JSON.parse(storedDocs).map((doc: any) => {
                if (doc.SourceDocumentID) {
                    updateStorageFlag = true;
                    return {
                        id: doc.id,
                        documentID: doc.SourceDocumentID,
                        documentFilePath: doc.SourceDocumentFilePath,
                        company: doc.CompanyName,
                        year: doc.Year,
                        documentType: {
                            en: doc.DocumentType,
                            fr: doc.DocumentType,
                        },
                        documentName: doc.SourceDocumentName,
                        content: doc.Content,
                        thumbnail: doc.ThumbnailImage,
                    } as IFullDocumentItem;
                }
                return doc;
            });

            if (updateStorageFlag) {
                setStoredDocs(JSON.stringify(docsArr));
            }
        }
    }, []);

    useEffect(() => {
        if (storedDocs && storedDocs !== "") {
            setFullDocuments(JSON.parse(storedDocs));
        } else {
            setFullDocuments([]);
        }
    }, [storedDocs]);

    return (
        <Tab.Panel className="w-full">
            <div className="grid grid-cols-3 gap-2 p-4">
                {fullDocuments.map((document: IFullDocumentItem, index: number) => (
                    <Bookmark
                        key={`bookmark-document-${index}`}
                        imgPath={`${import.meta.env.VITE_API_HOST}${document.thumbnail}`}
                        alt={`${document.company} ${document.documentType[language]}-${document.year}`}
                        id={document.documentID}
                        type={ResultTypeIndex.FullDocument}
                        storage={STORAGE_NAME}
                        storageID="documentID"
                    />
                ))}
            </div>
        </Tab.Panel>
    );
};

export default FullDocumentBookmarks;
