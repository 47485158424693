import * as React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useReadLocalStorage, useLocalStorage } from "usehooks-ts";
import { motion } from "framer-motion";
import * as BM from "./types";
import ImageBookmarks from "./ImageBookmarks";
import PageBookmarks from "./PageBookmarks";
import TextBookmarks from "./TextBookmarks";
import FullDocumentBookmarks from "./FullDocumentBookmarks";
import { clearAllBookmarks } from "~/utils/bookmarkUtils";
import { Tab } from "@headlessui/react";
import { Button as TabButton } from "react-aria-components";
import Button from "~/ui/Button/Button";

interface IBookmarksProps {
    onBack: () => void;
}

const Bookmarks: React.FunctionComponent<IBookmarksProps> = ({ onBack }) => {
    const { t } = useTranslation();
    const REGION = import.meta.env.VITE_REGION;
    const token = useReadLocalStorage("_t") as string;

    const [storedTexts, setStoredTexts] = useLocalStorage("texts", "");
    const texts: BM.ITextItem[] = storedTexts !== "" ? JSON.parse(storedTexts as string) : [];

    const imageStorage = REGION + "_images";
    const [storedImages, setStoredImages] = useLocalStorage(imageStorage, "");
    const images: BM.IImageItem[] = storedImages !== "" ? JSON.parse(storedImages as string) : [];

    const pageStorage = REGION + "_pages";
    const [storedPages, setStoredPages] = useLocalStorage(pageStorage, "");
    const pages: BM.IPageItem[] = storedPages !== "" ? JSON.parse(storedPages as string) : [];

    const [storedDocs, setStoredDocs] = useLocalStorage("fullDocuments", "");
    const fullDocuments: BM.IFullDocumentItem[] = storedDocs !== "" ? JSON.parse(storedDocs) : [];

    // const { data } = useSWR("/api/cart/count", (url) => fetcher(url, token));
    const [_type, setType] = React.useState<BM.ResultType>("images");
    const [downloading, setDownloading] = React.useState<boolean>(false);

    const resultTabs: Array<BM.ResultType> =
        REGION === "us" ? ["images", "pages", "texts"] : ["images", "pages", "fullDocuments"];

    const storage = {
        texts: {
            name: "texts",
            items: texts,
            type: BM.ResultTypeIndex.Text,
            id: "pageID",
        },
        images: {
            name: imageStorage,
            items: images,
            type: BM.ResultTypeIndex.Image,
            id: "imageID",
        },
        pages: {
            name: pageStorage,
            items: pages,
            type: BM.ResultTypeIndex.Page,
            id: "bookmarkId",
        },
        fullDocuments: {
            name: "fullDocuments",
            items: fullDocuments,
            type: BM.ResultTypeIndex.FullDocument,
            id: "documentID",
        },
    };

    const bookmarkCount = {
        images: images.length || 0,
        pages: pages.length || 0,
        texts: texts.length || 0,
        fullDocuments: fullDocuments.length || 0,
    };

    function clearAll() {
        const response = confirm(`Are you sure you want to clear all ${t(`searchResultTypes.${_type}`)} bookmarks?`);
        if (response) {
            // Update server data
            const itemIds = storage[_type].items.map((x) => x[storage[_type].id] as number);
            clearAllBookmarks(storage[_type].type, itemIds);

            // Update local storage
            switch (_type) {
                case "texts":
                    setStoredTexts("");
                    break;
                case "images":
                    setStoredImages("");
                    break;
                case "pages":
                    setStoredPages("");
                    break;
                case "fullDocuments":
                    setStoredDocs("");
                    break;
                default:
                    break;
            }
            // console.log(_type, "bookmarks cleared");
        }
    }

    function downloadAll() {
        const api = REGION === "us" ? "/api/cart/downloadus" : "/api/cart/downloadfr";
        let payload;
        let ids: number[];
        switch (_type) {
            case "images":
                ids = images.map((image) => image.imageID);
                payload = {
                    image: ids,
                };
                break;
            case "pages":
                ids = pages.map((page) => page.bookmarkId);
                payload = {
                    page: ids,
                };
                break;
            case "texts":
                ids = texts.map((text) => text.pageID);
                payload = {
                    text: ids,
                };
                break;
            case "fullDocuments":
                ids = fullDocuments.map((docs) => docs.documentID);
                payload = {
                    fulldoc: ids,
                };
                break;
            default:
                ids = images.map((image) => image.imageID);
                payload = {
                    image: ids,
                };
        }
        setDownloading(true);
        axios
            .post(`${import.meta.env.VITE_API_HOST}${api}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", "explore_" + _type + "_bookmarks_" + new Date().toLocaleDateString());
                document.body.appendChild(link);
                link.click();
                link.remove();
                setDownloading(false);
            })
            .catch((err) => {
                console.log(err);
                setDownloading(false);
            });
    }

    return (
        <motion.div
            initial={{ y: 48, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 48, opacity: 0 }}
            transition={{ spring: 0 }}
            className={`absolute inset-0 bg-surface rounded-2xl flex flex-col`}
        >
            {/** Header */}
            <div className="flex items-center p-4 pl-5">
                <Button
                    color="light"
                    icon="chevron_left"
                    size="lg"
                    noHover
                    onPress={onBack}
                    className="!min-w-max !h-10"
                />
                <h2 className="mx-4 mt-1 leading-none">{t("bookmarks")}</h2>
            </div>

            {/** Content */}
            <div className="flex-grow overflow-auto border-y border-white">
                <Tab.Group
                    as="div"
                    className="flex flex-col relative h-full flex-grow overflow-auto max-h-screen"
                    // defaultIndex={REGION === "us" ? 1 : 0}
                >
                    <Tab.List className="shrink-0 h-16 flex gap-2 py-4 px-8 text-foreground-dark">
                        {resultTabs.map((type: BM.ResultType, index: number) => (
                            <Tab as={React.Fragment} key={index}>
                                {({ selected }) => (
                                    <TabButton
                                        className={`h-[46px] text-sm focus-visible:outline-none px-5 rounded-full
                                        ${
                                            selected
                                                ? "text-white bg-primary"
                                                : "text-foreground-light bg-white shadow-sm"
                                        }`}
                                        onPress={() => setType(type)}
                                    >
                                        {t(`searchResultTypes.${type}`)}
                                        <span className="text-[15px] ml-2">{bookmarkCount[type]}</span>
                                    </TabButton>
                                )}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="w-full overflow-auto">
                        <ImageBookmarks />
                        <PageBookmarks />
                        {REGION === "us" && <TextBookmarks />}
                        {REGION === "fr" && <FullDocumentBookmarks />}
                    </Tab.Panels>
                </Tab.Group>
            </div>

            {/** Footer */}
            <div className="flex items-center justify-between pt-6 pb-5 px-8 bg-white">
                <Button
                    className="w-[170px]"
                    size="md"
                    icon="delete"
                    onPress={clearAll}
                    isDisabled={downloading || bookmarkCount[_type] === 0}
                >
                    {t("clearAll")}
                </Button>
                <Button
                    color="primary"
                    size="md"
                    icon="download"
                    onPress={downloadAll}
                    isDisabled={downloading || bookmarkCount[_type] === 0}
                >
                    {downloading ? t("preparingDownload") : t("downloadAll")}
                </Button>
            </div>
        </motion.div>
    );
};

export default Bookmarks;
