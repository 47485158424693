import { LabelTranslation } from "~/types";

export type ResultType = "images" | "pages" | "texts" | "fullDocuments";

export enum ResultTypeIndex {
    Text = 1,
    Image,
    Page,
    FullDocument,
}

export interface BookmarkItem {
    [key: string]: string | number | boolean | undefined;
    SourceDocumentID: number;
    SourceDocumentFilePath: string;
    DocumentType: string;
    Year: number;
}

export interface IImageItem {
    [key: string]: string | number | boolean | LabelTranslation | undefined;
    imageID: number;
    imageFilePath: string;
    fileDescription: string;
    company: string;
    documentType: LabelTranslation;
    metadata: LabelTranslation;
    year: number;
    index?: number;
}

export interface BookmarkTextItem extends BookmarkItem {
    CompanyName: string;
    Content: string;
    ThumbnailImage: string;
}

export interface IPageItem {
    [key: string]: string | number | boolean | undefined;
    bookmarkId: number;
    bookmarkName: string;
    company: string;
    pageNum: number;
    year: number;
    thumbnail: string;
    content: string;
    documentType: string;
    documentName: string;
}

export interface ITextItem {
    [key: string]: string | number | boolean | undefined;
    pageID: number;
    pageNum: number;
    company: string;
    year: number;
    thumbnail: string;
    content: string;
    documentType: string;
    documentName: string;
    highlight: string;
}

export interface IFullDocumentItem {
    [key: string]: any;
    documentID: number;
    documentFilePath: string;
    documentName: string;
    documentType: LabelTranslation;
    company: string;
    content: string;
    thumbnail: string;
    year: number;
}

export interface ITextDocumentItem extends BookmarkTextItem {
    PageID: number;
    PageNumber: number;
}
