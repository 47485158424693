import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { Tab } from "@headlessui/react";
import { IPageItem, ResultTypeIndex } from "./types";
import Bookmark from "~/components/Bookmark";

const PageBookmarks = () => {
    const STORAGE_NAME = import.meta.env.VITE_REGION + "_pages";
    const [storedPages, setStoredPages] = useLocalStorage(STORAGE_NAME, "");
    const [pages, setPages] = useState<IPageItem[]>([]);

    useEffect(() => {
        // Support for saved text bookmarks on previous version
        // Convert old data format to new text data format and update storage
        if (storedPages && storedPages !== "") {
            let updateStorageFlag = false;
            const pagesArr = JSON.parse(storedPages).map((page: any) => {
                if (page.SourceBookMarkID) {
                    updateStorageFlag = true;
                    return {
                        id: page.id,
                        bookmarkId: page.SourceBookMarkID,
                        bookmarkName: page.Category,
                        pageNum: page.StartPageNumber,
                        company: page.CompanyName,
                        year: page.Year,
                        documentType: page.DocumentType,
                        documentName: page.SourceDocumentName,
                        content: page.Content,
                        thumbnail: page.ThumbnailImage.substring(1),
                    } as IPageItem;
                }
                return page;
            });

            if (updateStorageFlag) {
                setStoredPages(JSON.stringify(pagesArr));
            }
        }
    }, []);

    useEffect(() => {
        if (storedPages && storedPages !== "") {
            setPages(JSON.parse(storedPages));
        } else {
            setPages([]);
        }
    }, [storedPages]);

    return (
        <Tab.Panel className="w-full">
            <div className="grid grid-cols-3 gap-2 p-4">
                {pages.map((page: IPageItem, index: number) => (
                    <Bookmark
                        key={`bookmark-page-${index}`}
                        imgPath={`${import.meta.env.VITE_API_HOST}/${page.thumbnail}`}
                        alt={`${page.company} ${page.documentType} ${page.year} ${page.bookmarkName}`}
                        id={page.bookmarkId}
                        type={ResultTypeIndex.Page}
                        storage={STORAGE_NAME}
                        storageID="bookmarkId"
                    >
                        <p className="text-xs text-center break-words p-2 align-middle">{page.bookmarkName}</p>
                    </Bookmark>
                ))}
            </div>
        </Tab.Panel>
    );
};

export default PageBookmarks;
