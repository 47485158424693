import apiService from "~/services/HttpService";

const httpPost = async (url: string, data:FormData) => {
    return new Promise((resolve, reject) => {
        try {
            apiService
                .post(url, data)
                .then((response) => {
                    if(response.status === 200) {
                        resolve(response.data);
                    }
                    else {
                        resolve(null);
                    }
                })
        } 
        catch(error) {
            console.error(error);
            reject(null);
        }
    });
};

const addBookmark = async (id:number, type:number) => {
    const api = '/api/favorite/add';
    const formData = new FormData();
    formData.append("sourceID", id.toString());
    formData.append("favoriteTypeID", type.toString());
    return httpPost(api, formData);
};

const deleteBookmark = async (id:number, type:number) => {
    const api = '/api/favorite/remove';
    const formData = new FormData();
    formData.append("sourceID", id.toString());
    formData.append("favoriteTypeID", type.toString());
    return httpPost(api, formData);
};

const clearAllBookmarks = async (type: number, bookmarkIds: number[]) => {
    const api = '/api/favorite/remove';
    const formData = new FormData();
    formData.append("favoriteTypeID", type.toString());

    try {
        const promises = bookmarkIds.map((id: number) => {
            formData.set("sourceID", id.toString());
            return httpPost(api, formData);
        });

        const results = await Promise.allSettled(promises);
        // results.forEach((result, index) => {
        //     if (result.status === 'fulfilled') {
        //         console.log(`Request ${index + 1} fulfilled:`, result.value);
        //     } else {
        //         console.error(`Request ${index + 1} rejected:`, result.reason);
        //     }
        // });

        return results;
    }
    catch(err: any) {
        console.error(err);
    }
};

export {
    addBookmark,
    deleteBookmark,
    clearAllBookmarks
};