import * as React from "react";
import axios from "axios";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useReadLocalStorage } from "usehooks-ts";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField, Input, Label } from "react-aria-components";
import FormModal from "./FormModal";

const changePasswordSchema = z
    .object({
        currentPassword: z.string().min(6, "Current password is required"),
        newPassword: z.string().min(6, "New password is required"),
        confirmNewPassword: z.string().min(6, "Confirm new password is required"),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
        message: "Passwords do not match",
        path: ["confirmPassword"],
    });

type changePasswordType = z.infer<typeof changePasswordSchema>;

const fields = [
    {
        name: "currentPassword",
    },
    {
        name: "newPassword",
    },
    {
        name: "confirmNewPassword",
    },
];

interface IChangePasswordProps {
    onBack: () => void;
}

const ChangePassword: React.FunctionComponent<IChangePasswordProps> = ({ onBack }) => {
    const token = useReadLocalStorage("_t");
    i18next.loadNamespaces("fields");
    i18next.loadNamespaces("errors");
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = React.useState(false);
    const [passwordUpdated, setPasswordUpdated] = React.useState(false);

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }, //, isSubmitting, isValid },
    } = useForm<changePasswordType>({
        resolver: zodResolver(changePasswordSchema),
        mode: "all",
    });

    const onSubmit = handleSubmit((data: changePasswordType) => {
        // console.log(data, isSubmitting, isValid, Object.keys(errors).length);

        setIsProcessing(true);

        axios
            .post(`${import.meta.env.VITE_API_HOST}/api/account/password`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                setPasswordUpdated(true);
            })
            .catch((error) => {
                const { status, data } = error.response;

                console.log(status, data);

                if (status === 400 && !data?.status) {
                    Object.keys(data).map((fieldName: string) =>
                        setError(fieldName as keyof changePasswordType, {
                            message: t(`errors:${data[fieldName]}`) || "",
                        })
                    );
                }
            })
            .finally(() => {
                setIsProcessing(false);
            });
    });

    return (
        <FormModal formTitle={`${t("changePassword")}`} isProcessing={isProcessing} onSubmit={onSubmit} onBack={onBack}>
            <div className="w-[471px] flex flex-col mx-auto py-6 gap-4">
                {passwordUpdated && <h4 className="text-center text-success">{t("passwordUpdated")}</h4>}
                {fields.map(({ name }) => (
                    <TextField
                        key={name}
                        className={`text-field ${errors[name as keyof changePasswordType] ? "text-field--error" : ""}`}
                    >
                        <Input
                            placeholder={t(name) || ""}
                            {...register(name as keyof changePasswordType)}
                            type="password"
                        />
                        <Label>
                            {errors?.[name as keyof changePasswordType]
                                ? errors?.[name as keyof changePasswordType]?.message
                                : t(`fields:${name}`)}{" "}
                        </Label>
                    </TextField>
                ))}
            </div>
        </FormModal>
    );
};

export default ChangePassword;
