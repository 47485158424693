import { FunctionComponent } from "react";
import ExploreLogo from "./ExploreLogo";
// import { Link, useLocation } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import Dashboard from "~/features/Dashboard/index";
import LanguageSwitcher from "./LanguageSwitcher";
// import Button from "~/ui/Button/Button";
// import AdvancedSearchBar from "~/features/Search/AdvancedSearchBar";

const AppHeader: FunctionComponent = () => {
    // const { t } = useTranslation();
    // const location = useLocation();
    // const REGION = import.meta.env.VITE_REGION;

    // const isCurrentPage = (pathname: string) => {
    //     return location.pathname.includes(pathname);
    // };

    return (
        <div className="fixed w-full inset-x-0 top-0 pt-7 px-2 md:px-6 lg:px-12 z-10 flex justify-between items-center">
            <ExploreLogo />

            {/* <div className="absolute flex gap-2 h-full items-center left-1/2 -translate-x-1/2">
                    <Link to="/search">
                        <Button color={!isCurrentPage("intelligence") ? "primary" : "secondary"}>{t("search")}</Button>
                    </Link>
                    {/* {REGION === "us" ? (
                        <Link
                            to="/reader-intelligence"
                            className={`btn btn--base btn--${
                                isCurrentPage("reader-intelligence") ? "primary" : "secondary"
                            }`}
                        >
                            {t("readerIntelligence")}
                        </Link>
                    ) : (
                    <Link
                        to="/market-intelligence"
                        className={`btn btn--base btn--${
                            isCurrentPage("market-intelligence") ? "primary" : "secondary"
                        }`}
                    >
                        {t(REGION === "us" ? "readerIntelligence" : "marketIntelligence")}
                    </Link>
                </div> */}

            <div className="flex flex-row">
                <LanguageSwitcher />
                <Dashboard />
            </div>
        </div>
    );
};

export default AppHeader;
