import React, { useEffect, useState } from "react";
import { DialogTrigger, Modal, Dialog, ModalOverlay } from "react-aria-components";

import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { fetcher } from "~/hooks/useFetcher";

import DocumentLinks from "~/components/DocumentLinks";
import ProfileUpdate from "../Account/Profile/ProfileUpdate";
import ChangePassword from "../Account/Profile/ChangePassword";
import Bookmarks from "../Account/Bookmarks/Bookmarks";
import BugReportForm from "./BugReportForm";
import IconButton from "~/ui/Button/Button";
import DashboardLink from "~/components/DashboardLink";

const Dashboard: React.FunctionComponent = () => {
    const token = useReadLocalStorage("_t") as string;
    const { data, error } = useSWR(`/api/account/details`, (url) => fetcher(url, token));
    // const { data: favorites } = useSWR(`/api/account/favorites`, (url) =>
    //   fetcher(url, token)
    // );

    const { t } = useTranslation();
    const [, setToken] = useLocalStorage("_t", null);
    const navigate = useNavigate();

    enum DASHBOARD_PANELS {
        profile = 1,
        password,
        bookmarks,
        bugreport,
    }

    const [currentPanel, setCurrentPanel] = useState<DASHBOARD_PANELS | null>(null);

    const togglePanel = (panel: DASHBOARD_PANELS) => {
        setCurrentPanel((prevState) => (prevState ? null : panel));
    };

    const closeAllPanels = () => {
        setCurrentPanel(null);
    };

    const logout = () => {
        setToken(null);
        navigate("/auth/login");
    };

    useEffect(() => {
        if (error && error?.response?.status === 401) {
            setToken(null);
            return navigate("/auth/login");
        }
        // reset(data);
    }, [data, error]);

    return (
        <DialogTrigger onOpenChange={closeAllPanels}>
            <IconButton color="secondary" size="lg" icon="person" iconFilled noHover className="shadow-md" />

            <ModalOverlay className="bg-black/30 backdrop-blur fixed inset-0 z-20 profile-modal">
                <Modal className="fixed inset-0 z-30 p-2 pb-6">
                    <Dialog className="h-full flex justify-end focus-visible:outline-none">
                        {({ close }) => (
                            <div className="w-[582px] rounded-2xl h-full relative flex flex-col bg-white !overflow-hidden">
                                <div className="p-8 flex border-b border-white">
                                    <h2>
                                        {t("goodMorning")}, {data.firstName}
                                    </h2>
                                    <IconButton
                                        icon="close"
                                        size="md"
                                        onPress={close}
                                        className="absolute top-3.5 right-2 z-10 !bg-transparent"
                                    />
                                </div>

                                <div className="flex-grow p-8">
                                    <p className="text-md mb-2">{t("myFavorites")}</p>
                                    <div className="grid grid-cols-1 pb-8">
                                        <DashboardLink
                                            label={t("bookmarks")}
                                            onClick={() => togglePanel(DASHBOARD_PANELS.bookmarks)}
                                        />
                                    </div>

                                    <p className="text-md mb-2">{t("help")}</p>
                                    <div className="grid grid-cols-1 pb-12">
                                        <DashboardLink
                                            label={t("bugReport.title")}
                                            onClick={() => togglePanel(DASHBOARD_PANELS.bugreport)}
                                        />
                                    </div>

                                    <p className="text-md mb-2">{t("myAccount")}</p>
                                    <div className="grid grid-cols-1 pb-8 gap-y-2">
                                        <DashboardLink
                                            label={t("changeMyProfile")}
                                            onClick={() => togglePanel(DASHBOARD_PANELS.profile)}
                                        />
                                        <DashboardLink
                                            label={t("changeMyPassword")}
                                            onClick={() => togglePanel(DASHBOARD_PANELS.password)}
                                        />
                                        <DashboardLink label={t("logout")} onClick={logout} />
                                    </div>

                                    <div className="w-full absolute bottom-4 right-0 px-8 flex justify-end">
                                        <DocumentLinks />
                                    </div>
                                </div>

                                <AnimatePresence>
                                    {currentPanel === DASHBOARD_PANELS.profile && (
                                        <ProfileUpdate
                                            onBack={() => togglePanel(DASHBOARD_PANELS.profile)}
                                            profileData={data}
                                        />
                                    )}

                                    {currentPanel === DASHBOARD_PANELS.password && (
                                        <ChangePassword onBack={() => togglePanel(DASHBOARD_PANELS.password)} />
                                    )}

                                    {currentPanel === DASHBOARD_PANELS.bookmarks && (
                                        <Bookmarks onBack={() => togglePanel(DASHBOARD_PANELS.bookmarks)} />
                                    )}

                                    {currentPanel === DASHBOARD_PANELS.bugreport && (
                                        <BugReportForm onBack={() => togglePanel(DASHBOARD_PANELS.bugreport)} />
                                    )}
                                </AnimatePresence>
                            </div>
                        )}
                    </Dialog>
                </Modal>
            </ModalOverlay>
        </DialogTrigger>
    );
};

export default Dashboard;
