import axios from "axios";
import i18next from "i18next";

export const fetcher = (url: string, token?: string) => {
  const { language } = i18next;
  const culture = language === "en" ? "en-US" : "fr-FR";

  return axios
    .get(`${import.meta.env.VITE_API_HOST}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        culture
      },
    })
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch((error) => {
      console.log(error);
      const {
        response: { status },
      } = error;
      if (status === 401) {
        // document.location.href = "/auth/login";
      }
      throw error;
    });
  }
