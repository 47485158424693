import { useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useReadLocalStorage } from "usehooks-ts";
import useWindowResize from "~/hooks/useWindowResize";
import Logo from "~/assets/logo-trans.png";
import LogoSmall from "~/assets/logo-icon.png";

// interface IExploreLogoProps {}

const ExploreLogo: React.FunctionComponent = () => {
    const token = useReadLocalStorage("_t") as string;
    const [logo, setLogo] = useState(Logo);
    const { windowSize } = useWindowResize();

    useLayoutEffect(() => {
        if (windowSize.width < 1024) {
            setLogo(LogoSmall);
        } else {
            setLogo(Logo);
        }
    }, [windowSize]);

    return (
        <Link to={`${token ? "/search" : "/auth/login"}`} className="lg:mr-8">
            <img className="h-[50px]" src={logo} alt="Explore Logo" />
        </Link>
    );
};

export default ExploreLogo;
