import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Button from "~/ui/Button/Button";

interface FormProps {
    formTitle: string;
    children: ReactNode;
    isProcessing: boolean;
    onSubmit: () => void;
    onBack: () => void;
}

const FormModal = (props: FormProps) => {
    const { formTitle, isProcessing, onSubmit, onBack, children } = props;
    const { t } = useTranslation();

    return (
        <motion.form
            initial={{ y: 48, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 48, opacity: 0 }}
            transition={{ spring: 0 }}
            className={`absolute inset-0 bg-surface rounded-2xl flex flex-col ${isProcessing ? "disabled" : ""}`}
            onSubmit={onSubmit}
        >
            {/** Header */}
            <div className="flex items-center p-4 pl-5">
                <Button
                    color="light"
                    icon="chevron_left"
                    size="lg"
                    noHover
                    onPress={onBack}
                    className="!min-w-max !h-10"
                />
                <h2 className="mx-4 mt-1 leading-none">{formTitle}</h2>
            </div>

            {/** Content */}
            <div className="flex-grow overflow-auto border-y border-white">{children}</div>

            {/** Footer */}
            <div className="flex items-center justify-center pt-6 pb-5">
                <Button
                    color="primary"
                    icon="edit"
                    size="md"
                    className="w-[196px]"
                    type="submit"
                    isDisabled={isProcessing}
                >
                    {t("update")}
                </Button>
            </div>
        </motion.form>
    );
};

export default FormModal;
