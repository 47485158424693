const CloseIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      d="M11.9998 13.4008L7.0998 18.3008C6.91647 18.4841 6.68314 18.5758 6.3998 18.5758C6.11647 18.5758 5.88314 18.4841 5.6998 18.3008C5.51647 18.1174 5.4248 17.8841 5.4248 17.6008C5.4248 17.3174 5.51647 17.0841 5.6998 16.9008L10.5998 12.0008L5.6998 7.10078C5.51647 6.91745 5.4248 6.68411 5.4248 6.40078C5.4248 6.11745 5.51647 5.88411 5.6998 5.70078C5.88314 5.51745 6.11647 5.42578 6.3998 5.42578C6.68314 5.42578 6.91647 5.51745 7.0998 5.70078L11.9998 10.6008L16.8998 5.70078C17.0831 5.51745 17.3165 5.42578 17.5998 5.42578C17.8831 5.42578 18.1165 5.51745 18.2998 5.70078C18.4831 5.88411 18.5748 6.11745 18.5748 6.40078C18.5748 6.68411 18.4831 6.91745 18.2998 7.10078L13.3998 12.0008L18.2998 16.9008C18.4831 17.0841 18.5748 17.3174 18.5748 17.6008C18.5748 17.8841 18.4831 18.1174 18.2998 18.3008C18.1165 18.4841 17.8831 18.5758 17.5998 18.5758C17.3165 18.5758 17.0831 18.4841 16.8998 18.3008L11.9998 13.4008Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
