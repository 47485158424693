import { Button } from "react-aria-components";
import Icon from "~/ui/Icon/Icon";

interface DashboardLinkProps {
    label: string;
    isLoading?: boolean;
    onClick: () => void;
}

const DashboardLink = (props: DashboardLinkProps) => {
    const { label, isLoading = false, onClick } = props;
    return (
        <Button
            className="profile-menu--item flex items-center p-4 shadow-sm rounded-2xl cursor-pointer"
            onPress={onClick}
            isDisabled={isLoading}
        >
            <div className="px-4 flex-grow">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p className="text-foreground-dark">{label}</p>
                    {isLoading && (
                        <div className="loader-container" style={{ marginLeft: "10px" }}>
                            <p>Loading...</p>
                        </div>
                    )}
                </div>
            </div>
            <Icon className="mr-2">chevron_right</Icon>
        </Button>
    );
};

export default DashboardLink;
