import { ReactNode } from "react";
import { useLocalStorage } from "usehooks-ts";
import { deleteBookmark } from "~/utils/bookmarkUtils";
import { Button } from "react-aria-components";
import CloseIcon from "~/ui/Icon/CloseIcon";

interface BookmarkProps {
    id: number;
    type: number;
    imgPath: string;
    alt: string;
    storage: string;
    storageID: string;
    children?: ReactNode[] | string | ReactNode;
}

const Bookmark = (props:BookmarkProps) => {
    const { id, type, imgPath, alt, storage, storageID } = props;
    const [storageItems, setStorageItems] = useLocalStorage(storage, "");

    const handleDelete = () => {
        // Delete from local storage
        const bookmarks = storageItems !== "" ? JSON.parse(storageItems) : [];
        const updatedBookmarks = bookmarks.filter(
            (bookmark:any) => bookmark[storageID] !== props.id
        );
        setStorageItems(JSON.stringify(updatedBookmarks));

        // Delete from server
        deleteBookmark(id, type);
    };

    return (
        <div className="rounded-2xl overflow-hidden shadow-sm border border-white relative group">
            <img src={imgPath} alt={alt} />
            { props.children }
            <div className=" w-full h-full absolute top-0 invisible group-hover:visible">
                <div className="bg-slate-300/25 w-full h-full">
                </div>
                <Button
                    className="btn--primary m-1 p-1 absolute top-0 right-0"
                    style={{"height": "auto"}}
                    onPress={handleDelete}            
                >
                    <CloseIcon className="w-4 h-4"/>
                </Button>
            </div>
        </div>
    );
};

export default Bookmark;