import { useEffect, useRef, useState } from "react";

const MOBILE_THRESHOLD = 600;

const useWindowResize = () => {
    const [isMobile, setIsMobile] = useState(
        window.innerWidth <= MOBILE_THRESHOLD
    );
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const prevWidth = useRef(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            const currWidth = window.innerWidth;
            if (
                currWidth <= MOBILE_THRESHOLD &&
                prevWidth.current > MOBILE_THRESHOLD
            ) {
                setIsMobile(true);
            } else if (
                currWidth > MOBILE_THRESHOLD &&
                prevWidth.current <= MOBILE_THRESHOLD
            ) {
                setIsMobile(false);
            }
            prevWidth.current = currWidth;

            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        isMobile,
        windowSize,
    };
};

export default useWindowResize;
