import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextField, Label, Input, Text, TextArea, FieldError } from "react-aria-components";
import i18next from "i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import Button from "~/ui/Button/Button";

import BugReportApi from "~/services/api/BugReportApi";
import { BugReportData, BugReportSchema } from "~/schemas/BugReportSchema";

interface FormProps {
    onBack: () => void;
}

const BugReportForm = (props: FormProps) => {
    const { onBack } = props;
    const { t } = useTranslation();
    i18next.loadNamespaces("errors");

    const [descChars, setDescChars] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<BugReportData>({
        resolver: zodResolver(BugReportSchema),
        mode: "all",
    });

    const onSubmit = handleSubmit((data: BugReportData) => {
        setIsProcessing(true);

        BugReportApi.sendBugReport(data).then((result: string) => {
            if (result === "SUCCESS") {
                setIsProcessing(false);
                setIsSent(true);
            }
        });
    });

    const updateCharCount = (event: any) => {
        const charCount = event.target.value.length;

        setDescChars(charCount);
    };

    // const [dropped, setDropped] = useState<string | null>(null);
    // const onDrop = (e) => {
    //     let files = e.items.filter((file: any) => file.kind === "file") as FileDropItem[];
    //     let filenames = files.map((file) => file.name);
    //     setDropped(filenames.join(", "));
    // };

    return (
        <motion.form
            initial={{ y: 48, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 48, opacity: 0 }}
            transition={{ spring: 0 }}
            className={`absolute inset-0 bg-surface rounded-2xl flex flex-col ${isProcessing ? "disabled" : ""}`}
            onSubmit={onSubmit}
        >
            {/** Header */}
            <div className="flex items-center p-4 pl-5">
                <Button
                    color="light"
                    icon="chevron_left"
                    size="lg"
                    noHover
                    onPress={onBack}
                    className="!min-w-max !h-10"
                />
                <h2 className="mx-4 mt-1 leading-none">{t("bugReport.title")}</h2>
            </div>

            {/** Content */}
            <div className="flex-grow overflow-auto border-y border-white">
                {isSent ? (
                    <div className="h-full w-[471px] flex items-center m-auto text-center">
                        <h3>{t("bugReport.sentMsg")}</h3>
                    </div>
                ) : (
                    <div className="w-[471px] flex flex-col mx-auto py-6 gap-6">
                        <h4>{t("bugReport.info")}</h4>

                        <TextField
                            className={`w-full text-field-2 ${errors["title"] ? "error" : ""}`}
                            onChange={() => {}}
                            isRequired
                        >
                            <Label>{t("bugReport.inputTitle")}</Label>
                            <Input type="text" maxLength={100} minLength={10} {...register("title")} />

                            {errors["title"] && (
                                <FieldError className="text-error my-1">
                                    <small>{t(`errors:${errors["title"]?.message}`)}</small>
                                </FieldError>
                            )}
                        </TextField>

                        <TextField className={`w-full text-field-2 ${errors["description"] ? "error" : ""}`} isRequired>
                            <Label className="!mb-1">{t("bugReport.inputSteps")}</Label>
                            <Text slot="description" className="mb-2 mx-1 flex justify-between">
                                <small>{t("bugReport.stepsDesc")}</small>
                                <small>[ {descChars} / 600 ]</small>
                            </Text>
                            <TextArea
                                rows={10}
                                maxLength={600}
                                {...register("description")}
                                onChange={updateCharCount}
                            ></TextArea>
                            {errors["description"] && (
                                <FieldError className="text-error my-1">
                                    <small>{t(`errors:${errors["description"]?.message}`)}</small>
                                </FieldError>
                            )}
                        </TextField>

                        {/* <TextField className="w-full text-field-2">
                        <Label>Screenshots or videos</Label>
                        <DropZone
                            className="file-dropzone"
                            onDrop={onDrop}
                            getDropOperation={(types) => (types.has("image/png") ? "copy" : "cancel")}
                        ></DropZone>
                        </TextField> */}
                    </div>
                )}
            </div>

            {/** Footer */}
            <div className="flex items-center justify-center pt-6 pb-5">
                {!isSent ? (
                    <Button color="primary" size="md" className="w-[196px]" type="submit" isDisabled={isProcessing}>
                        {t("submit")}
                    </Button>
                ) : (
                    <Button color="primary" size="md" className="w-[196px]" onPress={onBack}>
                        {t("return")}
                    </Button>
                )}
            </div>
        </motion.form>
    );
};

export default BugReportForm;
