import { BugReportData } from "~/schemas/BugReportSchema";
import apiService from "../HttpService";

const API_SEND_REPORT = "/api/bug/create";

export default class BugReportApi {
    static sendBugReport = async (data: BugReportData) => {
        return new Promise<string>((resolve, reject) => {
            try {
                apiService.post(API_SEND_REPORT, data).then((response) => {
                    if (response.status === 200) {
                        resolve(response.data.status);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    };
}
